/**
 * color map
 * names determined using tool located at: http://chir.ag/projects/name-that-color
 */
$alazarincrimson: #da291c;
$alto: #dbdbdb;
$black: #000;
$bondiblue: #008ab1;
$boulder: #747474;
$dovegray: #707070;
$loblolly: #c3cfd3;
$mineshaft: #363636;
$mineshaftalt: #292929;
$rollingstone: #77888d;
$wasabi: #78952b;
$white: #fff;

/* font settings */
$primary-font: 'Helvetica Neue LT Pro', sans-serif;

// px values at 16px em base
$base: 1rem; // 16px
$p: 1.125rem; // 18px
$h1: 2.0625rem; // 33px
$h2: 1.875rem; // 30px
$h3: 1.5rem; // 24px
$h4: 1.25rem; // 20px
$h5: 1.125rem; // 18px
$small: 0.875rem; // 14px

/* properties */

/* z-index levels */
$z0: 0;
// views
$z1: 9;
$z2: 99;
// tabcontent
$z3: 999;
// butt bar
$z4: 9999;
$z5: 99999;
// side bar
$z6: 999999;
$z7: 9999999;
// modal & drawer
$z8: 99999999;
$z9: 999999999;

/* responsiveness */
$breakpoints: (
  tablet: 960px,
  short: 610px,
);
