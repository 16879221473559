@import 'styles/resources';

.setting {
  @include flex(align-center justify-between);
  @include size(100%, 90px);
  padding: 0 32px 0 15px;
  border-top: 1px solid $dovegray;
  color: $white;
  cursor: pointer;
  user-select: none;

  div {
    @include fontstyle(16, 22, $font-weight: 700);
  }

  small {
    @include fontstyle(12, 16);
  }

  svg {
    width: 24px;
    fill: $bondiblue;
  }

  a {
    @include fontstyle(12, 16, $font-weight: 600);
    color: $bondiblue;
    text-decoration: underline;
  }

  .widget-onoff {
    @include size(37px, 19px);
    position: relative;
    transition: background 200ms;
    border-radius: 10px;
    background: rgba($bondiblue, 0.5);
    &:after {
      @include position(absolute, 0 null null 0);
      @include size(19px);
      content: '';
      display: block;
      transition: all 200ms;
      border: 1px solid $bondiblue;
      border-radius: 50%;
      opacity: 0.5;
      background-color: $white;
    }
  }

  &.is-active {
    .widget-onoff {
      background: $bondiblue;
      &:after {
        transform: translateX(100%);
        opacity: 1;
      }
    }
  }

  &.is-disabled {
    pointer-events: none;
    * {
      opacity: 0.25;
    }
  }
}
