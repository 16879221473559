@import 'styles/resources';

.postgame {
  @include position(absolute, -4px -1px -6px -1px);
  @include flex(column align-center);
  padding: 12px 20px 16px;
  transition: scale 300ms, opacity 300ms;
  border-radius: 12px;
  opacity: 0;
  background-color: $mineshaftalt;
  box-shadow: inset 3px 3px 3px #00000029;
  color: $white;
  pointer-events: none;
  scale: 0.9;

  .close-button {
    @include position(absolute, 9px 10px null null);
  }

  &.is-active {
    opacity: 1;
    pointer-events: auto;
    scale: 1;
  }

  .next-puzzle {
    margin-bottom: 25px;
  }

  .guess {
    align-self: start;
    margin: 14px 0 25px;
  }

  .body {
    padding: 0 7px;
    p {
      @include fontstyle(12, 16);
      margin-bottom: 1em;
      color: $white;
      a {
        color: $alazarincrimson;
      }
    }
  }

  .share-button {
    margin: auto 0 25px;
  }

  .stats-link {
    @include fontstyle(16, 22, $font-weight: 700);
    button {
      padding: 0;
      border: none;
      background: none;
      color: $bondiblue;
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
      text-decoration: underline;
      cursor: pointer;
      text-underline-offset: 2px;
    }
  }
}
