@import 'styles/resources';

.keyboard {
  @include flex(column align-center justify-center);
  gap: 7px;
  margin-top: auto;

  > div {
    @include flex(align-center);
    width: 100%;
    gap: 5px;

    .spacer {
      flex: 0.5;
    }
    .key {
      flex: 1;
    }
    .key.alt {
      flex: 1.5;
    }
  }
}
