@import 'styles/resources';

.ribbon {
  @include flex(align-center justify-center);
  @include fontstyle(16, 22, $font-weight: 700);
  @include position(fixed, 200px null null 50%);
  padding: 12px 20px;
  border-radius: 4px;
  opacity: 0;
  background-color: $alazarincrimson;
  box-shadow: 0 3px 6px #0000005a;
  color: $white;
  pointer-events: none;
  translate: -50% 0;

  &.is-active {
    transition: opacity 200ms;
    opacity: 1;
  }
}
