@import 'styles/resources';

.instructions {
  p {
    @include fontstyle(12, 16);
    margin: 1em 0;
    color: $white;
    span {
      color: $alazarincrimson;
    }
    &.wider {
      margin: 1em -12px;
    }
    &.center {
      text-align: center;
    }
  }
}
