@import 'styles/resources';

.modal-screen {
  @include flex(column align-center);
  @include position(absolute, 42px 0 0);
  z-index: $z1;
  padding: 14px 20px;
  transition: scale 300ms, opacity 300ms;
  opacity: 0;
  background-color: $mineshaftalt;
  pointer-events: none;
  scale: 0.9;
  &.is-active {
    opacity: 1;
    pointer-events: auto;
    scale: 1;
  }

  .close-button {
    @include position(absolute, 12px 13px null null);
  }

  .panel-heading {
    @include fontstyle(18, 26, $font-weight: 700);
    align-self: start;
    color: $bondiblue;
  }
}
