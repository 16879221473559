@import 'styles/resources';

.tile {
  @include fontstyle(18, 18, $font-weight: 700);
  @include size(36px);
  border-radius: 9px;

  color: $black;
  text-transform: uppercase;
  @include media('height>=short') {
    @include size(40px);
    font-size: 1.25rem;
  }

  .inner {
    @include size(100%);
    position: relative;
    transform-style: preserve-3d;
  }

  &:not(.unsolved) .inner {
    animation: 800ms forwards tileflip;
  }

  &:nth-child(1) .inner {
    animation-delay: 0;
  }
  &:nth-child(2) .inner {
    animation-delay: 100ms;
  }
  &:nth-child(3) .inner {
    animation-delay: 200ms;
  }
  &:nth-child(4) .inner {
    animation-delay: 300ms;
  }
  &:nth-child(5) .inner {
    animation-delay: 400ms;
  }
  &:nth-child(6) .inner {
    animation-delay: 500ms;
  }
  &:nth-child(7) .inner {
    animation-delay: 600ms;
  }
  &:nth-child(8) .inner {
    animation-delay: 700ms;
  }

  .front,
  .back {
    @include flex(align-center justify-center);
    @include position(absolute, 0);
    @include size(100%);
    backface-visibility: hidden;
    border-radius: 9px;
    box-shadow: 0 2px 6px #00000033;
  }

  .front {
    background-color: $white;
  }

  .back {
    transform: rotateY(180deg);
    &.incorrect {
      background-color: $boulder;
    }

    &.present {
      background-color: $bondiblue;
      color: $white;
    }

    &.correct,
    &.solved {
      background-color: $wasabi;
      color: $white;
    }
  }

  &.solved {
    @include fontstyle(16, 16, $font-weight: 700);
    @include size(35px);
    background-color: $wasabi;
    color: $white;
  }
}

@keyframes tileflip {
  from {
    transform: rotateY(0);
  }

  to {
    transform: rotateY(180deg);
  }
}
