@import 'styles/resources';

.time-solved {
  @include flex(column align-center);
  color: $white;
  .message {
    @include fontstyle(14, 20, $font-weight: 700);
    text-align: center;
  }
  .time {
    @include fontstyle(30, 42, $font-weight: 300);
    color: $wasabi;
  }
}
