@import 'styles/resources';

.stats {
  @include flex(column align-center);
  @include size(100%);

  .time-solved {
    margin: 50px 0 27px;
  }

  .statlines {
    width: 100%;
  }

  .share-button {
    margin: auto 0 50px;
  }
}
