html,
body {
  @include size(100%);
  margin: 0;
  overflow: hidden;
}

// lock in images to the viewport width site-wide
img {
  max-width: 100%;
  height: auto;
}

// remove the additional border highlighting
*:focus {
  outline: none;
}

html {
  box-sizing: border-box;
}

*,
::before,
::after {
  box-sizing: inherit;
  background-repeat: no-repeat;
}

#root {
  @include size(100%);
}

.app-wrap {
  @include flex(column);
  @include size(100%);
  position: relative;
  max-width: 480px;
  max-height: 745px;
  margin: 0 auto;
  padding: 0 8px 12px;
  background: linear-gradient(
    152deg,
    $loblolly 0%,
    #ffffff 36%,
    $rollingstone 100%
  );
}
