@import 'styles/resources';

.logo {
  z-index: $z2;
  width: 24px;
  line-height: 0;

  svg {
    transition: fill 300ms;
    fill: $black;
  }

  &.alt svg {
    fill: $white;
  }
}
