@import 'styles/resources';

.guess {
  @include flex(column);
  margin-bottom: 9px;

  .clue {
    @include flex();
    @include fontstyle(10, 10, $font-weight: 600);
    padding: 0 0 1px 8px;
    color: $black;

    svg {
      @include size(15px, 11px);
      position: relative;
      top: -4px;
      margin: 0 4px;
      fill: $black;
    }
  }

  .guideline {
    @include size(4px, 11px);
    border-width: 1px 0 0 1px;
    border-style: solid;
    border-color: $black;
  }

  .copy {
    position: relative;
    top: -3px;
  }

  .line {
    @include flex();
    gap: 3px;
  }

  .cursor {
    @include size(36px, 3px);
    margin: 33px 0 0 1px;
    animation: 1s blink step-end infinite;
    background-color: $alazarincrimson;
    box-shadow: 0 2px 6px #00000033;
    @include media('height>=short') {
      @include size(40px, 3px);
      margin-top: 37px;
    }
  }

  &.solved {
    .clue svg {
      fill: $white;
    }
    .guideline {
      border-color: $white;
    }
  }
}

@keyframes blink {
  from,
  to {
    background-color: transparent;
    box-shadow: none;
  }
  50% {
    background-color: $alazarincrimson;
    box-shadow: 0 2px 6px #00000033;
  }
}

@keyframes flipTile {
  from {
    transform: rotateY(0);
  }
  to {
    transform: rotateY(180deg);
  }
}
