@import 'styles/resources';

.key {
  @include flex(align-center justify-center);
  @include fontstyle(12, 16, $font-weight: 700);
  @include size(30px, 42px);
  border-radius: 4px;
  background-color: $alto;
  box-shadow: 0 2px 6px #00000033;
  color: $mineshaft;
  text-transform: uppercase;
  cursor: pointer;
  user-select: none;
  @include media('height>=short') {
    height: 54px;
  }

  &.correct {
    background-color: $wasabi;
    color: $white;
  }

  &.present {
    background-color: $bondiblue;
    color: $white;
  }

  &.incorrect {
    background-color: $boulder;
  }

  &.alt {
    @include fontstyle(11, 15, $font-weight: 700);
    width: 43px;
  }

  &.back {
    svg {
      @include size(24px, 18px);
      fill: $mineshaft;
    }
  }
}
