@import 'styles/resources';

.share-button {
  @include flex(align-center);
  @include fontstyle(16, 22, $font-weight: 700);
  position: relative;
  padding: 8px 28px;
  border-radius: 19px;
  background-color: $alazarincrimson;
  color: $white;
  cursor: pointer;
  gap: 14px;
  svg {
    @include size(16px, 18px);
    fill: $white;
  }

  &:before {
    @include flex(align-center justify-center);
    @include position(absolute, 40px null null 50%);
    content: 'Copied to Clipboard';
    display: block;
    padding: 4px 12px;
    opacity: 0;
    background-color: $white;
    color: $mineshaft;
    font-size: 10px;
    white-space: nowrap;
    pointer-events: none;
    translate: -50% -20px;
    scale: 0.5;
  }

  &.is-active {
    &:before {
      transition: all 200ms;
      opacity: 1;
      translate: -50% 0;
      scale: 1;
    }
  }
}
