@import 'styles/resources';

.gameboard {
  @include flex(column);
  position: relative;
  flex-grow: 1;

  > p {
    @include fontstyle(16, 16, $font-weight: 600);
    margin-top: 65px;
    padding: 0 20px;
    color: $black;
  }
}
