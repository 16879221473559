@import 'styles/resources';

.close-button {
  @include size(29px, 26px);
  cursor: pointer;
  svg {
    @include size(100%);
    fill: $white;
  }
}
