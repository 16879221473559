@import 'styles/resources';

.hamburger {
  @include size(30px);
  position: relative;
  z-index: $z2;
  cursor: pointer;

  div {
    @include size(100%, 3px);
    position: absolute;
    top: 50%;
    transition: background-color 300ms, rotate 150ms, top 150ms 150ms,
      opacity 1ms 150ms;
    background-color: $black;

    &:first-child {
      top: calc(50% - 8px);
    }

    &:last-child {
      top: calc(50% + 8px);
    }
  }

  &.alt {
    div {
      top: 50%;
      transition: background-color 300ms, rotate 150ms 150ms, top 150ms,
        opacity 1ms 150ms;
      background-color: $white;

      &:first-child {
        rotate: -45deg;
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:last-child {
        rotate: 45deg;
      }
    }
  }
}
