@import 'styles/resources';

.alert {
  @include position(absolute, 0);
  z-index: $z9;
  transition: opacity 300ms;
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  pointer-events: none;

  &.is-active {
    opacity: 1;
    pointer-events: auto;
    .inner {
      transform: translate(-50%, -50%) scale(1);
    }
  }

  .inner {
    @include flex(column align-center);
    @include fontstyle(18, 26);
    @include position(absolute, 50% null null 50%);
    @include size(375px, 280px);
    padding: 14px 20px;
    transform: translate(-50%, -50%) scale(0.9);
    transition: transform 300ms;
    background-color: $mineshaftalt;
    color: $white;
  }

  .close-button {
    @include position(absolute, 12px 13px null null);
  }

  .panel-heading {
    @include fontstyle(18, 26, $font-weight: 700);
    align-self: start;
    color: $bondiblue;
  }
}
