@import 'styles/resources';

.heading {
  @include fontstyle(22, 30, 0.55, 900);
  z-index: $z2;
  padding: 1px 14px;
  border-radius: 16px;
  background-color: $alazarincrimson;
  box-shadow: 0 3px 6px #00000029;
  color: $white;
}
