@import 'styles/resources';

.next-puzzle {
  @include fontstyle(16, 22, $font-weight: 700);
  color: $white;
  span {
    color: $bondiblue;
    font-weight: 300;
  }
}
