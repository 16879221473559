@import 'styles/resources';

.statline {
  @include flex(align-center justify-between);
  padding: 16px 20px;
  border-bottom: 1px solid $dovegray;
  &:first-child {
    border-top: 1px solid $dovegray;
  }

  .stat {
    @include fontstyle(16, 22, $font-weight: 700);
    color: $white;
  }

  .value {
    @include fontstyle(18, 26, $font-weight: 700);
    color: $wasabi;
  }
}
