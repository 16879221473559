/* =Typography
----------------------------------------------- */

@font-face {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 900;
  src: url('/fonts/HelveticaNeueLTPro-Hv.ttf');
}

@font-face {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/HelveticaNeueLTPro-Bd.ttf');
}

@font-face {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 600;
  src: url('/fonts/HelveticaNeueLTPro-Md.ttf');
}

@font-face {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/HelveticaNeueLTPro-Roman.ttf');
}

@font-face {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 300;
  src: url('/fonts/HelveticaNeueLTPro-Lt.ttf');
}

// pretty up everything
div,
p,
small,
h1,
h2,
h3,
h4,
h5,
h6,
li,
button,
svg text,
input,
textarea,
select {
  font-family: $primary-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

p,
li {
  font-size: $p;
  font-weight: 400;
  line-height: 1.5rem; // 24px
}
h1 {
  font-size: $h1;
  font-weight: 700;
  line-height: 2.5rem; // 40px
}
h2 {
  font-size: $h2;
  font-weight: 700;
  line-height: 2.3125rem; // 37px
}
h3 {
  font-size: $h3;
  font-weight: 600;
  line-height: 1.75rem; // 30px
}
h4 {
  font-size: $h4;
  font-weight: 300;
  line-height: 1.75rem; // 28px
}
h5 {
  font-size: $h5;
  font-weight: 400;
  line-height: 1.375rem; // 22px
}
small {
  font-size: $small;
  line-height: 1.125rem; // 18px
}

/* set up the core element typography */

p,
h1,
h2,
h3,
h4,
small {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: underline;

  &:active {
    color: inherit;
  }

  &:visited {
    color: inherit;
  }
}

ul {
  margin: 0;
  padding-left: 1.25em;

  li {
    margin-bottom: 0.5em;
  }
}

// text styling helper classes
.w-light {
  font-weight: 300;
}
.w-book {
  font-weight: 400;
}
.w-medium {
  font-weight: 500;
}
.w-bold {
  font-weight: 600;
}
.w-xbold {
  font-weight: 700;
}
.uppercase {
  text-transform: uppercase;
}
.lowercase {
  text-transform: lowercase;
}
.capitalize {
  text-transform: capitalize;
}
.italic {
  font-style: italic;
}
.underline {
  text-decoration: underline;
}
