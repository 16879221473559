@import 'styles/resources';

.menu {
  @include flex(column align-center);
  @include position(absolute, 0);
  z-index: $z1;
  padding: 105px 20px 52px;
  transition: scale 300ms, opacity 300ms;
  opacity: 0;
  background-color: $mineshaftalt;
  pointer-events: none;
  scale: 0.9;
  &.is-active {
    opacity: 1;
    pointer-events: auto;
    scale: 1;
  }

  .copyright {
    @include fontstyle(9, 13);
    margin-top: auto;
    color: $white;
  }
}
